@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  scroll-behavior: smooth; /* 부드러게 */
}

.link-icon {
}
.link-icon.twitter {
  background-image: url(../public/img/icon-twitter.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
}
